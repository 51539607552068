@use "@angular/material" as mat;
@use "sass:map";

// Include the core Angular Material styles
@include mat.core();

:root {
  --primary: #21415a;
  --primary-50: #000000;
  --primary-100: #01213a;
  --primary-200: #1a3a53;
  --primary-300: #34546d;
  --primary-400: #4d6d86;
  --primary-500: #6787a0;
  --primary-600: #80a0b9;
  --primary-700: #9abad3;
  --primary-800: #b3d3ec;
  --primary-900: #d2ebff;
  --primary-A100: #e8f5ff;
  --primary-A200: #f6fbff;
  --primary-A400: #ffffff;
  // --primary-A700: #fcfcff;
  --on-primary: #ffffff;
  --on-primary-50: #ffffff;
  --on-primary-100: #ffffff;
  --on-primary-200: #ffffff;
  --on-primary-300: #ffffff;
  --on-primary-400: #ffffff;
  --on-primary-500: #ffffff;
  --on-primary-600: #000000;
  --on-primary-700: #000000;
  --on-primary-800: #000000;
  --on-primary-900: #000000;
  --on-primary-A100: #000000;
  --on-primary-A200: #000000;
  --on-primary-A400: #000000;
  // --on-primary-A700: #ffffff;

  --accent: #eb6d68;
  --accent-50: #000000;
  --accent-100: #510300;
  --accent-200: #8a0f0a;
  --accent-300: #a32823;
  --accent-400: #d75c57;
  --accent-500: #f07570;
  --accent-600: #ff938f;
  --accent-700: #ffb7b4;
  --accent-800: #ffdbda;
  --accent-900: #fff8f8;
  --accent-A100: #fff8f8;
  --accent-A200: #fff8f8;
  --accent-A400: #fff8f8;
  // --accent-A700: #eb3573;
  --on-accent: #ffffff;
  --on-accent-50: #ffffff;
  --on-accent-100: #ffffff;
  --on-accent-200: #ffffff;
  --on-accent-300: #ffffff;
  --on-accent-400: #ffffff;
  --on-accent-500: #ffffff;
  --on-accent-600: #000000;
  --on-accent-700: #000000;
  --on-accent-800: #000000;
  --on-accent-900: #000000;
  --on-accent-A100: #000000;
  --on-accent-A200: #000000;
  --on-accent-A400: #000000;
  // --on-accent-A700: #000000;

  --warn: #656666;
  --warn-50: #000000;
  --warn-100: #191a1a;
  --warn-200: #323333;
  --warn-300: #4c4d4d;
  --warn-400: #656666;
  --warn-500: #7f8080;
  --warn-600: #989999;
  --warn-700: #b2b3b3;
  --warn-800: #cbcccc;
  --warn-900: #e5e6e6;
  --warn-A100: #f1f2f2;
  --warn-A200: #f9fafa;
  --warn-A400: #ffffff;
  // --warn-A700: #eb3573;
  --on-warn: #000000;
  --on-warn-50: #000000;
  --on-warn-100: #000000;
  --on-warn-200: #000000;
  --on-warn-300: #000000;
  --on-warn-400: #000000;
  --on-warn-500: #000000;
  --on-warn-600: #ffffff;
  --on-warn-700: #ffffff;
  --on-warn-800: #ffffff;
  --on-warn-900: #ffffff;
  --on-warn-A100: #ffffff;
  --on-warn-A200: #ffffff;
  --on-warn-A400: #ffffff;
  // --on-warn-A700: #000000;
}

// Generate Primary, Accent and Warn palettes
$palettes: ();

@each $name in (primary, accent, warn) {
  $palettes: map.merge(
    $palettes,
    (
      #{$name}:
        (
          50: var(--#{$name}-50),
          100: var(--#{$name}-100),
          200: var(--#{$name}-200),
          300: var(--#{$name}-300),
          400: var(--#{$name}-400),
          500: var(--#{$name}-500),
          600: var(--#{$name}-600),
          700: var(--#{$name}-700),
          800: var(--#{$name}-800),
          900: var(--#{$name}-900),
          contrast: (
            50: var(--on-#{$name}-50),
            100: var(--on-#{$name}-100),
            200: var(--on-#{$name}-200),
            300: var(--on-#{$name}-300),
            400: var(--on-#{$name}-400),
            500: var(--on-#{$name}-500),
            600: var(--on-#{$name}-600),
            700: var(--on-#{$name}-700),
            800: var(--on-#{$name}-800),
            900: var(--on-#{$name}-900),
          ),
          default: var(--#{$name}),
          lighter: var(--#{$name}-100),
          darker: var(--#{$name}-700),
          text: var(--#{$name}),
          default-contrast: var(--on-#{$name}),
          lighter-contrast: var(--on-#{$name}-100),
          darker-contrast: var(--on-#{$name}-700),
        )
    )
  );
}

$custom-typography-config: mat.define-typography-config(
  $font-family: "Inter",
  $headline-1:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 42px,
      $line-height: 46px,
    ),
  $headline-2:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 42px,
      $line-height: 46px,
    ),
  $headline-3:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 42px,
      $line-height: 46px,
    ),
  $headline-4:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 42px,
      $line-height: 46px,
    ),
  $headline-5:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 42px,
      $line-height: 46px,
    ),
  $headline-6:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 34px,
      $line-height: 41px,
    ),
  $subtitle-1:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 28px,
      $line-height: 36px,
    ),
  $subtitle-2:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 22px,
      $line-height: 29px,
    ),
  $body-1:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 14px,
      $line-height: 22px,
    ),
  $body-2:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 14px,
      $line-height: 22px,
      $font-weight: bold,
    ),
  $caption:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 12px,
      $line-height: 22px,
    ),
  $button:
    mat.define-typography-level(
      $font-family: "Inter",
      $font-size: 14px,
      $line-height: 20px,
    ),
);
$primary-palette: (
  50: var(--primary-50),
  100: var(--primary-100),
  200: var(--primary-200),
  300: var(--primary-300),
  400: var(--primary-400),
  500: var(--primary-500),
  600: var(--primary-600),
  700: var(--primary-700),
  800: var(--primary-800),
  900: var(--primary-900),
  A100: var(--primary-A100),
  A200: var(--primary-A200),
  A400: var(--primary-A400),
  contrast: (
    50: var(--on-primary-50),
    100: var(--on-primary-100),
    200: var(--on-primary-200),
    300: var(--on-primary-300),
    400: var(--on-primary-400),
    500: var(--on-primary-500),
    600: var(--on-primary-600),
    700: var(--on-primary-700),
    800: var(--on-primary-800),
    900: var(--on-primary-900),
    A100: var(--on-primary-A100),
    A200: var(--on-primary-A200),
    A400: var(--on-primary-A400),
  ),
);
$accent-palette: (
  50: var(--accent-50),
  100: var(--accent-100),
  200: var(--accent-200),
  300: var(--accent-300),
  400: var(--accent-400),
  500: var(--accent-500),
  600: var(--accent-600),
  700: var(--accent-700),
  800: var(--accent-800),
  900: var(--accent-900),
  A100: var(--accent-A100),
  A200: var(--accent-A200),
  A400: var(--accent-A400),
  contrast: (
    50: var(--on-accent-50),
    100: var(--on-accent-100),
    200: var(--on-accent-200),
    300: var(--on-accent-300),
    400: var(--on-accent-400),
    500: var(--on-accent-500),
    600: var(--on-accent-600),
    700: var(--on-accent-700),
    800: var(--on-accent-800),
    900: var(--on-accent-900),
    A100: var(--on-accent-A100),
    A200: var(--on-accent-A200),
    A400: var(--on-accent-A400),
  ),
);
$warn-palette: (
  50: var(--warn-50),
  100: var(--warn-100),
  200: var(--warn-200),
  300: var(--warn-300),
  400: var(--warn-400),
  500: var(--warn-500),
  600: var(--warn-600),
  700: var(--warn-700),
  800: var(--warn-800),
  900: var(--warn-900),
  A100: var(--warn-A100),
  A200: var(--warn-A200),
  A400: var(--warn-A400),
  contrast: (
    50: var(--on-warn-50),
    100: var(--on-warn-100),
    200: var(--on-warn-200),
    300: var(--on-warn-300),
    400: var(--on-warn-400),
    500: var(--on-warn-500),
    600: var(--on-warn-600),
    700: var(--on-warn-700),
    800: var(--on-warn-800),
    900: var(--on-warn-900),
    A100: var(--on-warn-A100),
    A200: var(--on-warn-A200),
    A400: var(--on-warn-A400),
  ),
);
// Generate Angular Material themes. Since we are using CSS Custom Properties,
// we don't have to generate a separate Angular Material theme for each color
// set. We can just create one light and one dark theme and then switch the
// CSS Custom Properties to dynamically switch the colors.
$my-primary: mat.define-palette($primary-palette);
$my-accent: mat.define-palette($accent-palette);
$my-warm: mat.define-palette($warn-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warm: $my-warm,
    ),
    typography: $custom-typography-config,
  )
);
@include mat.all-component-themes($my-theme);
@include mat.typography-hierarchy($my-theme);
// $on-primary: if(contrast-tone($my-primary) == 'dark', #000, #fff) !default;

// body.light,
// body .light {
//   $light-theme: mat.define-light-theme((color: ($palettes)));

//   // Use all-component-colors to only generate the colors
//   @include mat.all-component-colors($light-theme);
// }

// body.dark,
// body .dark {
//   $dark-theme: mat.define-dark-theme((color: ($palettes)));

//   // Use all-component-colors to only generate the colors
//   @include mat.all-component-colors($dark-theme);
// }

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }
html,
body {
  width: 100%;
  overscroll-behavior: none;
  // height: 100% !important;
}

body {
  margin: 0 auto;
  @include mat.all-component-themes($my-theme);
  font-family: "Inter";
}

/* ********** */
/* flex box */
.ov {
  overflow: visible !important;
}

.container-column {
  flex: 1 1 100%;
  overflow: auto;
  .btn-end {
    text-align: right;
  }
}

.column {
  display: flex;
  flex-direction: column;
}
.container-row-only {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.start-flex {
  display: flex;
  justify-content: flex-start;
}

.center-flex {
  display: flex;
  justify-content: center;
}

.end-flex {
  display: flex;
  justify-content: flex-end;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.center-center {
  display: flex;
  align-items: center;
}

.align-items {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.content-center {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.gap8 {
  display: flex;
  gap: 8px;
}

.mt15 {
  margin-top: 15px;
}

.gap15 {
  display: flex;
  gap: 10px 15px;
}

.gap40 {
  display: flex;
  gap: 40px;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.close-btn {
  justify-content: flex-end;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  box-shadow:
    0px 32px 64px rgba(0, 0, 0, 0.16),
    0px 8px 16px -8px rgba(0, 0, 0, 0.24) !important;
  border-radius: 15px !important;
}

.card-half {
  background: #ffffff;
  border: 1px solid #d3d5da;
  border-radius: 16px;
  // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  padding: 16px 32px 16px 32px;
  // height: 204px;
}

.content-search-add {
  display: flex;
  align-items: flex-start;
}

.content-search-add
  > button.mdc-button.mdc-button--unelevated.mat-mdc-unelevated-button.mat-unthemed.mat-mdc-button-base {
  width: 45%;
}

/* ************ flow 3.1 ******** */
.bg-green {
  background: #f9fbfc;
}

.flow3-1 {
  ngx-dropzone-label {
    margin: 0px !important;
    display: contents;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: start;
    z-index: 10;

    img.img-label {
      margin-right: 15px;
    }
  }

  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background: transparent !important;
  }

  .mat-stepper-horizontal-line {
    max-width: 60px !important;
  }

  .close-new {
    margin-bottom: -50px;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .drag-and-drop {
    height: auto !important;
    word-break: break-all;
    align-items: start !important;
    background: transparent;
    color: #212936;
    border: none;
  }

  ngx-dropzone-remove-badge {
    pointer-events: fill;
    opacity: 1 !important;
    background: transparent !important;
    cursor: pointer;
    margin: 17px 26px 0 0;
  }
}

.label-stepper-new {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 80%;
  display: inline-block;
}

.metadataDialog {
  width: 100% !important;
  height: 100vh !important;
}

.card-basic-new {
  background: #ffffff;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
}

.text14 {
  color: #1c1b1f;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
}

.border16 {
  border-radius: 16px;
}

/* ************ ************ *************/
/* ********** */
.container {
  width: 100%;
}

.content {
  margin: 0 64px 0 64px;
}

.custom-h1 {
  font-size: 1.25em !important;
  margin: 0px !important;
}

.custom-h3 {
  font-weight: 700;
  font-size: 1em;
  line-height: 1.1em;
}

.custom-h5 {
  font-size: 0.75em;
}

.w100 {
  width: 100% !important;
}

.w90 {
  width: 90% !important;
}

.w80 {
  width: 80% !important;
}

.w73 {
  width: 73% !important;
}

.w70 {
  width: 70% !important;
}

.w60 {
  width: 60% !important;
}

.w50 {
  width: 50% !important;
}

.w48 {
  width: 48% !important;
}

.w40 {
  width: 40% !important;
}

.w30 {
  width: 30% !important;
}

.w20 {
  width: 20% !important;
}

.h50 {
  height: 50px !important;
}

.font1-875 {
  font-size: 1.875em !important;
}

.font1 {
  font-size: 1em !important;
}

.font085 {
  font-size: 0.875em !important;
}

.font125 {
  font-size: 1.25em !important;
}

.font24px {
  font-size: 24px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}
.pb45 {
  padding-bottom: 45px !important;
}
.pt30px {
  padding: 30px 0 0 0;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}

.pl15pt7 {
  padding-left: 15px;
  padding-top: 7px;
}

.pt50px {
  padding-top: 50px;
}

.pt180 {
  padding-top: 180px;
}

.mt6px {
  margin-top: 6px;
}

.mt10px {
  margin-top: 10px;
}

.mt20px {
  margin-top: 20px;
}

.mt30px {
  margin-top: 30px;
}

.mt40px {
  margin-top: 40px;
}

.mt45px {
  margin-top: 45px;
}

.mt55px {
  margin-top: 55px;
}

.mt60px {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt180 {
  margin-top: 180px;
}

.mb8px {
  margin-bottom: 8px;
}

.mb20px {
  margin-bottom: 20px;
}

article.br20 {
  border-radius: 20px !important;
}

.mb25px {
  margin-bottom: 25px;
}

.mb30px {
  margin-bottom: 30px;
}

.mb60px {
  margin-bottom: 60px;
}

.mb110 {
  margin-bottom: 110px;
}

.mr10px {
  margin-right: 10px;
}

.mr30px {
  margin-right: 30px;
}

.mr6 {
  margin-right: 6px;
}

.minus-mt {
  margin-top: -18px;
}

.bold {
  font-weight: bold !important;
}

.normal {
  font-weight: 400 !important;
}

.bold-strong {
  font-weight: 800 !important;
  font-family: "Inter", sans-serif !important;
}

.jc-end {
  justify-content: end;
  display: flex;
}

.dialogJobRight {
  width: 40%;
}

.text-center {
  text-align: center;
}

.text-orange {
  color: #eb6d69 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-red-info {
  color: #b3261e;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.text-red {
  color: #ea0606;
}

.text-normal {
  font-weight: normal !important;
}

.bg-grey {
  background-color: #f9fbfc;
}

.wst {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.input-search {
  margin-left: 30px;
  width: 80%;
}

.squar-btn-accent {
  padding: 5px;
  border-radius: 4px;
  background: #eb6d68;
  color: #fff;
  margin-top: -13px;
  margin-left: -15px !important;
  width: 23px;
  display: block;
  height: 26px;
}

.drag-and-drop {
  height: auto !important;
  min-height: 220px;
  word-break: break-all;
  align-items: start !important;
}

.m40-info-red {
  margin: 0 0px 40px 0;
}

.card {
  padding: 20px;
}

.card:hover {
  background: #3e3e3e;
}

.card-dark-grey {
  background: #3e3e3e;
  padding: 20px;
}

.card-basic {
  border: 1px solid #e5e6e6;
  border-radius: 16px;
  padding: 20px;
  background: #ffffff;
}

.title-id {
  color: #6b7380;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}

.border-btm-dark {
  border-bottom: 2px solid #292929;
}

.label-stepper {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.big-text {
  font-size: 1.25em;
  font-weight: bold;
  font-family: "Inter", sans-serif !important;
  line-height: 1.75em;
}

.rightCol {
  padding: 0px 0 0 30px;
  width: 35%;
}

.link-orange {
  color: #eb6d69 !important;
  text-decoration: none;
  font-weight: bold;
}

// .active-link {
//   border-bottom: 2px solid #ffffff !important;
//   border-radius: 2px !important;
//   height: 65px;
// }

.align-end {
  display: flex;
  justify-content: flex-end;
}

.title-right {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #6b7380;
  margin-bottom: 32px;
}

.title-params {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.9);
}

.chips-green {
  background: #5ab274 !important;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  padding: 0 10px 0 10px;
}

.chips-grey {
  background: #f1f2f2 !important;
  color: #6b7380 !important;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  border-radius: 8px;
  padding: 0 10px 0 10px;
  width: 85px;
  text-align: center;
}

.custom-divider {
  margin: 0px -20px 32px -20px;
  border-top: 1px solid #e5e6e6;
}

a.file-path {
  text-decoration: underline dashed;
  margin-right: 27px;
  color: #000000;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.chips-span {
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  color: #000000;
}

.brand {
  margin: -30px 0 35px 0;
  align-self: center;

  img {
    height: 45px !important;
    width: auto !important;
  }
}

.errors {
  padding-left: 16px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-top: -20px;

  .errors-ul {
    margin-top: 0;
  }
}

.errors-verification-code {
  padding-left: 16px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-top: -26px;
  margin-bottom: 15px;
}

.container-flex {
  display: flex;
  flex-direction: column;
}

// ****************
// we replace the styles only before UI - Kit
// ****************
.bg-grey
  > mat-sidenav-container.mat-drawer-container.mat-sidenav-container.mat-drawer-transition {
  height: calc(100vh - 97px);
}

.mat-mdc-button.mat-accent,
.mat-mdc-outlined-button.mat-accent {
  border-color: #eb6d68 !important;
}

.mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: none;
  background: #f9fbfc;
}

.mat-drawer-side.mat-drawer-end {
  border: none !important;
  border-left: none;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px/20px Inter !important;
}

.mat-mdc-raised-button[disabled][disabled] {
  --mdc-filled-button-container-color: #f9bfbd;
  background: #f9bfbd;
}

.mat-step-icon {
  height: 31px !important;
  width: 31px !important;
}

.mat-step-label-selected {
  font-size: 1em;
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined.mdc-text-field--no-label {
  background: #fff;
  height: 36px !important;
  align-items: center !important;
}

.mdc-data-table__header-cell {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.mdc-data-table__cell {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

mat-toolbar.mat-toolbar.mat-toolbar-multiple-rows {
  background: #ffffff !important;
  border-bottom: 1px solid #d3d5da !important;
  position: sticky !important;
  top: 0;
  z-index: 99999;
}

body .mat-mdc-table .mdc-data-table__row {
  height: 32px;
  width: 100%;
}

body .mat-step-header .mat-step-icon-selected,
body .mat-step-header .mat-step-icon-state-done {
  background: var(--accent);
}

.mat-step-icon-state-edit {
  background-color: #9e9e9e !important;
}

// .mat-icon.material-icons.ng-star-inserted {
//   background-color: #9E9E9E !important;
//   color: green;
//   position: relative;
//   left: -15px;
//   &::before {
//     content: './assets/icons/check.svg';
//     color: #fff;
//     position: relative;
//     left: 15px;
//     font-weight: bold;
//   }
// }
body .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #eb6d68 !important;
  --mdc-filled-button-label-text-color: #fff;
}

.active-link {
  border-bottom: 2px solid #eb6d68 !important;
  border-radius: 2px !important;
  height: 65px !important;
}

.active-link > .mdc-button__label {
  font-weight: bold;
}

.active {
  background: orangered;
}

.sample-sets-view {
  width: 50vw;
  height: 100%;
  border-right: 1px solid #d3d5da;
  overflow: hidden;
}

.title-set {
  white-space: nowrap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.9);
}

.bg-sample {
  background: #f9fbfc url("./assets/images/bg-sample.png") no-repeat right
    bottom;
  // height: 100%;
  min-height: calc(100vh - 95px);
}

.bg100 {
  background: #f9fbfc url("./assets/images/bg-sample.png") no-repeat right
    bottom;
  // height: 100%;
  min-height: calc(100vh - 65px);
}

body .mat-drawer-container {
  height: 100%;
}

body .mat-elevation-z8,
body .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: none;
}

.h100 {
  height: calc(100vh - 65px) !important;
}

.h100vh {
  height: 100vh !important;
}

.mdc-checkbox__background {
  background: #ffffff !important;
  border: 1.5px solid #d2d5da !important;
  border-radius: 6px !important;
}

// .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
//   background-color: transparent !important;
// }

.mat-mdc-checkbox-checked .mdc-checkbox__background {
  background-color: #21415a !important;
  color: #fff !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: #fff !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  outline: 1px solid #f9fbfc;
}

::-webkit-scrollbar-thumb {
  background-color: #d3d5da;
  // outline: 1px solid #D3D5DA;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  z-index: 0;
}

.position-fix {
  position: fixed;
  margin-right: 65px;
}

table.table-right td {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border: none;
  padding: 8px 8px 0px 0px !important;
}

table.table-right.mdc-data-table__row:not(
    .mdc-data-table__row--selected
  ):hover {
  background-color: transparent !important;
}

.mat-drawer-inner-container {
  overflow: hidden;
}

.csv-result-table {
  height: calc(100vh - 145px);
  min-height: calc(100vh - 200px);
  thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.job-results {
  thead {
    position: relative !important;
    // top: 0;
  }
}

/* ----------- iPad Mini and Air ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .border-dashed {
    width: 89% !important;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  .border-dashed {
    width: 89% !important;
  }
}

@media screen and (max-width: 1280px) {
  .input-search {
    visibility: hidden;
    width: 0 !important;
    gap: 0 !important;
  }

  .import-btn {
    padding-left: -170px !important;
  }

  .input-new {
    visibility: visible !important;
    padding-bottom: 20px;
  }

  .container-right {
    display: flex;
    flex-direction: column;
  }

  .article-container {
    justify-content: space-between;
  }

  .content-step {
    width: 17% !important;
  }
}

.login-container {
  width: 224px;
  // height: 334px;
  border-radius: 4px !important;
  border: 1px solid #d3d5da;
  box-shadow:
    0px 32px 64px rgba(0, 0, 0, 0.16),
    0px 8px 16px -8px rgba(0, 0, 0, 0.24) !important;
  // display: flex !important;
  // flex-direction: row !important;
  // justify-content: center !important;
  z-index: 99999;
  // text-align: center !important;
}

.img-icon-menu {
  span.mdc-list-item__primary-text {
    justify-items: center;
    display: flex;
    gap: 13px;
  }
}

.head {
  padding-bottom: 24px;
  position: sticky;
  top: 64px;
  background: #f9fbfc;
  z-index: 998;
}

.mdc-list-item__content span {
  padding: 44px 103px 44px 17px;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-mdc-snack-bar-container {
  &.my-custom-snackbar-arhive {
    --mdc-snackbar-container-color: #ffffff !important;
    --mat-mdc-snack-bar-button-color: #eb6d68 !important;
    --mdc-snackbar-supporting-text-color: #313033 !important;
    margin-top: 76px;
    white-space: break-spaces;
  }
  &.my-custom-snackbar-error {
    --mdc-snackbar-container-color: #ffffff !important;
    --mat-mdc-snack-bar-button-color: #eb6d68 !important;
    --mdc-snackbar-supporting-text-color: #313033 !important;
    margin-top: 76px;
    white-space: break-spaces;
  }

  &.app-notification-error {
    --mdc-snackbar-container-color: #f23a2f;
  }

  &.my-custom-snackbar-error::before {
    content: url("./assets/icons/check.svg");
    top: 0px;
    left: 10px;
    position: absolute;
    z-index: 999;
    margin-right: 30px !important;
  }

  &.custom-snackbar-error {
    --mdc-snackbar-container-color: #ffffff !important;
    --mat-mdc-snack-bar-button-color: #eb6d68 !important;
    --mdc-snackbar-supporting-text-color: #313033 !important;
    margin-top: 76px;
    white-space: break-spaces;
  }

  &.custom-snackbar-error::before {
    content: url("./assets/icons/info-red.svg");
    top: 0px;
    left: 10px;
    position: absolute;
    z-index: 999;
    margin-right: 30px !important;
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    margin-left: 20px;
  }

  &.app-notification-success {
    --mdc-snackbar-container-color: #43a446;
  }
  &.download-key-snackbar {
    margin-top: 200px !important;
  }
}

svg line {
  stroke-width: 2px;
  stroke: rgba(0, 0, 0, 0.54) !important;
}

ngx-dropzone-remove-badge {
  // background-color: red;
  pointer-events: fill;
  opacity: 1 !important;
  background: transparent !important;
  cursor: pointer;
}

.content-upload {
  pointer-events: fill;
}

.spinner-executing {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #5ab274;
    width: 100% !important;
    height: 100% !important;
  }
}

// .cdk-global-overlay-wrapper{
//   background: rgba(0, 0, 0, 0.38);
// }

.app-container {
  padding: 2rem 1rem 5rem;
}

.app-mat-divider {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: #f5f7f9 !important;
}

span.mdc-list-item__primary-text {
  width: 97% !important;
}

.mat-progress-spinner circle {
  fill: transparent;
  transform-origin: center;
  transition: stroke-dashoffset 225ms linear;
}

//   .mdc-circular-progress__indeterminate-container{
//     position: absolute;
//     width: 20px !important;
//     height: 20px !important;
//     overflow: hidden;
//     border-color: rgba(103, 58, 183, 0.12);
//     border-radius: 50%;
//     border-style: solid;
//     border-width: 4px;
// }
.mdc-circular-progress--indeterminate
  .mdc-circular-progress__determinate-container {
  opacity: 1;
}

.container-spinner {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-toolbar.mat-primary {
  background: transparent !important;
}

.mdc-snackbar {
  height: 50px !important;
}

.metadataDialog {
  .mat-mdc-dialog-content {
    max-height: 100vh !important;
  }

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 0px !important;
  }
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 10px !important;
}

.text.text-center.mb60px.err-width > ul li {
  text-align: start !important;
}

.add-new-user {
  min-height: 500px;
}

.tooltip {
  position: absolute;
  background-color: #ffffff;
  color: #6b7380;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  margin-bottom: 10px;
}

.highlight {
  color: red;
  font-weight: bold;
}

.green-circle {
  background: #5ab274;
  color: #ffffff;
  border: 1px solid #5ab274;
}

.circle-small {
  color: #6b7380;
  border: 1px solid #6b7380;
}

.circle {
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  border-radius: 50%;
  font-family: "Inter";
}

.triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #ffffff;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.add-new-org {
  margin-top: 60px;
}

// ::ng-deep .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
//   background-color: #5AB274;
//   /*replace with your color*/
// }

// ::ng-deep .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
//   background-color: rgb(113, 226, 147) !important;
//   /*replace with your color*/
// }
// .mat-mdc-slide-toggle .mdc-form-field .mdc-switch {
//   --mdc-switch-selected-track-color: #5AB274;
//   --mdc-switch-unselected-track-color: gray;

//    --mdc-switch-selected-icon-color: yellow;
//    --mdc-switch-unlected-icon-color: black;
// }

// .mdc-switch__icon .mdc-switch__icon--off{
//   background-color: #5AB274;
// }

.mat-mdc-slide-toggle {
  --mdc-switch-selected-handle-color: #ffbb1c;
  --mdc-switch-selected-pressed-handle-color: #ffbb1c;
  --mdc-switch-selected-pressed-state-layer-color: #ffbb1c;
  --mdc-switch-selected-hover-state-layer-color: #ffbb1c;
  --mdc-switch-selected-hover-handle-color: #ffbb1c;
  --mdc-switch-selected-focus-state-layer-color: #ffbb1c;
  --mdc-switch-selected-focus-handle-color: #ffbb1c;
  --mdc-switch-selected-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-focus-track-color: #e0e0e0;
}
